import {
  EligiblePackageInfo,
  PackageSubscription,
  SubscribedPackageInfo,
  VariantInfo,
  VariantsToTerm,
} from '@manageSubscription/Types';
import { getDiscounts } from './promoPackageUtils';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { MAX_PKG_TERM_IN_MONTHS, MAX_PKG_TIER } from '@manageSubscription/utils/constants';
import {
  filterEligiblePackages,
  findHighestSubscription,
  getAlaCartePackagesHigherThanSubscribed,
  getTierPackagesHigherThanSubscribed,
} from '@manageSubscription/utils';
import { partition } from 'lodash';

export const getEligibleTrialPackages = (ePackages: EligiblePackageInfo[], sPackages: SubscribedPackageInfo[] = []) => {
  if (!ePackages?.length) {
    return [];
  }
  const trialOfferTypes = [OfferType.Trial, OfferType.Trial_renewal];

  const eligibleTrialPackages = filterEligiblePackages(ePackages, trialOfferTypes);
  const trialSubscriptions = sPackages.filter((sPkg) => isTrialPackage(sPkg.variant));
  let filteredTrialPackages = eligibleTrialPackages;

  if (trialSubscriptions?.length > 0) {
    filteredTrialPackages = eligiblePackagesHigherThanSubscribed(eligibleTrialPackages, trialSubscriptions);
  }
  return mapTrialPackageVariant(filteredTrialPackages);
};

const eligiblePackagesHigherThanSubscribed = (
  ePackages: EligiblePackageInfo[],
  subscriptions: SubscribedPackageInfo[],
): EligiblePackageInfo[] => {
  const [eTiered, eNonTiered] = partition(ePackages, (ePkg) => ePkg.tier);
  const [sTiered, sNonTiered] = partition(subscriptions, (sPkg) => sPkg.tier);

  const ePackagesHigherThanSubscribed: EligiblePackageInfo[] = [];

  if (eTiered?.length > 0) {
    const highestTieredSubscription = findHighestSubscription(sTiered);
    const tierPackagesHigherThanSubscribed = getTierPackagesHigherThanSubscribed(eTiered, highestTieredSubscription);
    ePackagesHigherThanSubscribed.push(...tierPackagesHigherThanSubscribed);
  }

  if (eNonTiered?.length > 0) {
    const alaCartePackagesHigherThanSubscribed = getAlaCartePackagesHigherThanSubscribed(eNonTiered, sNonTiered);
    ePackagesHigherThanSubscribed.push(...alaCartePackagesHigherThanSubscribed);
  }
  return ePackagesHigherThanSubscribed;
};

/**
 * Method to find a trial variant that matches given ccRequired flag and set as default variant
 * OR set the first one as default, when no matching ccRequired variant found.
 */
export const mapTrialPackageVariant = (trialPackages: EligiblePackageInfo[], isCCRequired = false) => {
  return trialPackages.map((pkg) => {
    const { variants } = pkg;
    const variant =
      variants.find((trialVariant) => trialVariant.discounts[0].ccRequired === isCCRequired) || variants[0];
    return { ...pkg, variant };
  });
};

/**
 * Method responsible to return tiered, eligiblePaid packages that matches:
 * - Selected trials with ccRequired = true
 * - shouldMatchTrialWithCCRequired = true
 * If selected trials are non-tiered or does not match any of the above criteria then return eligiblePackages as it is.
 */
export const getPackagesMatchingTrials = (
  ePackages: EligiblePackageInfo[],
  packages: PackageSubscription[],
  shouldMatchTrialWithCCRequired: boolean,
) => {
  const trialEligibleList = trialEligiblePackages(packages);
  if (shouldMatchTrialWithCCRequired && trialEligibleList?.length > 0) {
    return ePackages.filter((ePkg) => trialEligibleList.some((pkg) => pkg.id === ePkg.id));
  }
  return ePackages;
};

/**
 * Method responsible to group trial options when multiple variant package available
 * E.g. [{Pkg1: variants: [3M-v11, 6M-v12]}, {Pkg2: variants:[12M-v21]}], then
 * returns - {3: {3M-v11, 12M-v21}, 6: {6M-v12, 12M-v21}}
 */
export const extractTrialVariantsToTerm = (trialPackages: EligiblePackageInfo[]): VariantsToTerm => {
  const trialVariantsToTerm = {} as VariantsToTerm;

  const sortedPackages = trialPackages.sort((p1, p2) => p2.variants.length - p1.variants.length);
  const [multiVariantPackage, ...singleVariantPackages] = sortedPackages;

  multiVariantPackage.variants.forEach((variant) => {
    const packageWithVariant = { ...multiVariantPackage, variant };
    if (!trialVariantsToTerm[variant.initialTerm]) {
      trialVariantsToTerm[variant.initialTerm] = [];
    }
    trialVariantsToTerm[variant.initialTerm].push(packageWithVariant);
  });

  singleVariantPackages.forEach((singleVariantPackage) => {
    Object.values(trialVariantsToTerm).forEach((packages) => {
      packages.push(singleVariantPackage);
    });
  });

  return trialVariantsToTerm;
};

export const findMatchingTerm = (packagesToTerm: VariantsToTerm, selectedPackages: EligiblePackageInfo[]) => {
  const entries = Object.entries(packagesToTerm);

  for (const [key, packages] of entries) {
    if (packages.every((p) => selectedPackages?.find((sp) => p.variant.id === sp.variant.id))) {
      return key;
    }
  }
  return null;
};

export const hasOneTrialEligibleRestNonEligible = (trialPackages: EligiblePackageInfo[]): boolean => {
  const allTrialsHasOneVariant = trialPackages?.every((pkg) => pkg.variants?.length === 1) ?? false;
  if (!allTrialsHasOneVariant) {
    return false;
  }
  const [trialEligible, nonTrialEligible] = partition(trialPackages, (pkg) => isTrialEligibleVariant(pkg.variant));
  return trialEligible?.length === 1 && nonTrialEligible?.length >= 1;
};

export const hasTrialEligiblePackages = (packages: PackageSubscription[] | EligiblePackageInfo[]) => {
  return trialEligiblePackages(packages)?.length > 0;
};

export const hasMultipleTrialPackages = (trialPackages: EligiblePackageInfo[]) => {
  return trialPackages?.length > 1;
};

export const hasOnlyOneTrialEligibleVariant = (trialPackages: EligiblePackageInfo[]) => {
  return trialPackages?.length === 1 && trialPackages[0].variant.discounts.some((d) => d.ccRequired);
};

export const hasMultipleTrialVariant = (trialPackages: EligiblePackageInfo[]) => {
  return trialPackages.some((p) => p.variants.length > 1);
};

export const isTrialEligibleVariant = (variant: VariantInfo) => {
  return isTrialPackage(variant) && variant.discounts[0].ccRequired;
};

export const trialEligiblePackages = (packages: PackageSubscription[] | EligiblePackageInfo[]) => {
  return packages?.filter((pkg) => isTrialEligibleVariant(pkg.variant));
};

export const hasGoodwill = (variant: VariantInfo) => {
  if (!variant) {
    return false;
  }
  return variant.discounts.some((discount) => {
    const isGoodwill =
      Boolean(discount.goodwillReason) ||
      Boolean(discount.goodwillTerm) ||
      Boolean(discount.goodwillTermUnit) ||
      Boolean(discount.goodwillTerm);
    return isGoodwill;
  });
};

export const getTrialSubscriptions = (packages: PackageSubscription[]) => {
  return packages?.filter(({ variant }) => isTrialPackage(variant));
};

export const containsTrialPackage = (packages: PackageSubscription[]) =>
  !!packages.length && packages.some(({ variant }) => isTrialPackage(variant));

export const containOnlyTrialPackages = (packages: PackageSubscription[]) =>
  !!packages.length && packages.every(({ variant }) => isTrialPackage(variant));

export const isTrialPackage = (variant: VariantInfo) => {
  if (!variant) return false;
  return getDiscounts(variant.discounts, [OfferType.Trial, OfferType.Trial_renewal]).length > 0;
};

export const isHighestTrialSubscription = (pkg: SubscribedPackageInfo) => {
  return pkg.tier === MAX_PKG_TIER && pkg.variant.renewalTerm == MAX_PKG_TERM_IN_MONTHS && isTrialPackage(pkg.variant);
};
