import attachCapableServices from '@api/requests/subscription/attachCapableServices';
import { TokenTypes } from '@api/services/StoreService';
import { RootState } from '@app/reducers';
import { useConfig } from '@components/ConfigProvider';
import { searchEligiblePromoCodePackages } from '@cv/portal-cps-lib';
import { OfferType, SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import {
  EligiblePackagesResponse,
  SearchEligiblePromoCodePackagesRequest,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useQuery } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import urlSearchParams, { parsePromoCodeCriteria } from '@utils/urlSearchParams';
import { useSelector } from 'react-redux';
import useVehicle from './useVehicle';
import { getPackagesMatchingCriteria } from '../../components-lib/manageSubscription/utils/packageUtils';
import { useFeatureFlags } from '@components/FeatureFlags';
import { previewOrders } from '@app/components-lib';
import { EligiblePackageInfo } from '@app/components-lib/manageSubscription/Types';
import { determineListPrice } from '@app/components-lib/manageSubscription/utils/promoPackageUtils';

export type PromoCodePackagesQueryOptions = {
  onSuccess?: () => void;
};

const usePromoCodePackages = () => {
  const { data: vehicle } = useVehicle();
  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const vehicleId: string = vehicle?.vehicleId;
  const config = useConfig();
  const environment = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);
  const featureFlags = useFeatureFlags();

  const { p: promoCode = '', pc = '' } = urlSearchParams.getAll();
  const promoCodeCriteria = parsePromoCodeCriteria(pc);

  return useQuery<EligiblePackagesResponse[]>({
    enabled: Boolean(vehicleId && promoCode),
    queryKey: ['eligiblePackagesWithPromo', promoCode, promoCodeCriteria, featureFlags.shouldIncludeTax],
    queryFn: async () => {
      const request: SearchEligiblePromoCodePackagesRequest = {
        data: {
          userId,
          vehicleId,
          promoCode,
          salesChannel: SalesChannel.Subscriber,
        },
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
        },
        queryParams: {
          include: 'variantOptions',
        },
      };

      const response = await searchEligiblePromoCodePackages(request)
        .then((response) => getPackagesMatchingCriteria(response.data, promoCodeCriteria))
        .then((packages) => {
          if (featureFlags.shouldIncludeTax) {
            return Promise.all(
              packages.map(async (promoPkg) => {
                const previewResult = await previewOrders(
                  {
                    accessToken,
                    userDetails: { userId },
                    vehicleDetails: { vehicleId },
                    salesChannel: SalesChannel.Subscriber,
                    tenantId,
                    location: undefined,
                    config,
                  },
                  [promoPkg],
                  [],
                  '',
                  { promoCode },
                );
                promoPkg.taxTotal = Number(previewResult.taxTotal);
                return promoPkg;
              }),
            );
          }
          return packages;
        })
        .then(sortPromoCodeDiscounts)
        .then(computeVariantListPrice)
        .then((packages) =>
          attachCapableServices({
            packages: packages,
            capableServices: vehicle?.capableServices,
          }),
        )
        .catch((e) => {
          console.error(e);
          return [];
        });

      return response;
    },
    placeholderData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default usePromoCodePackages;

export const sortPromoCodeDiscounts = (packages: EligiblePackageInfo[]): EligiblePackageInfo[] =>
  packages.map((pkg) => ({
    ...pkg,
    variant: {
      ...pkg.variant,
      discounts:
        pkg.variant.discounts?.sort((firstDiscount) => {
          if (firstDiscount.offerType === OfferType.Promo_code) {
            return -1;
          }
          return 0;
        }) || [],
    },
  }));

export const computeVariantListPrice = (packages: EligiblePackageInfo[]) => {
  return packages.map((promoPkg) => ({
    ...promoPkg,
    variant: {
      ...promoPkg.variant,
      listPrice: determineListPrice(promoPkg.variant),
    },
  }));
};
